<template>
    <div class="home" >
      <div class="login">
        <div class="login-wrap">
          <p class="title">비밀번호 변경</p>
            <p>비밀번호는 1개월에 한 번씩 <br>영문 + 숫자 + 특수문자, 8자리 이상으로<br>변경해 주셔야 합니다.</p>
            <div class="pwd-change-form">
              <input type="password" v-model="login.cur_pwd" placeholder="현재 비밀번호를 입력해주세요."/>
              <input type="password" v-model="login.new_pwd" placeholder="변경할 비밀번호를 입력해주세요."/>
              <input type="password" v-model="login.new_rpw" @keyup.enter="login.doEnter" placeholder="변경할 비밀번호를 다시 한번 입력해주세요."/>
            </div>
            <button class="auth_btn" @click="login.doSubmit" >비밀번호 변경하기</button>
        </div>
      </div>
    </div>
</template>
<script>
// @ is an alias to /src 
import {onMounted, reactive} from 'vue'
import {useRouter} from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import { useStore } from 'vuex'

export default {
    components: {
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store = useStore();
        const login = reactive({
            idx:'',
            uid : "",
            cur_pwd:"",
            new_pwd:"",
            new_rpw:"",
            access_code : "",
            check:'N',
            auth:'N',
            info:{},

            doSubmit : () => {
                let params = {
                    uid : login.uid,
                    cur_pwd : login.cur_pwd,
                    new_pwd : login.new_pwd,
                    new_rpw : login.new_rpw,
                };
                axios.post("/rest/mypims/changePassword", params).then((res) => {
                    if( res.data.err == 0 ){
                      login.info = res.data;
                      login.auth='Y';
                      store.commit('setUserInfo', login.info);
                      store.commit('setAuth');
                      router.push({
                        name : 'main'
                      });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            doEnter : () => {
                login.doSubmit();
            },
        });
        onMounted(() => {
          // Mounted
          console.log("pwdChange");
          login.idx = store.state.userLoginIdx;
          login.uid = store.state.userLoginUid;
          login.pwd = 0;

          if ( login.idx == 0) {
            router.back(-1);
          }

        });

        return {login};
    }
}
</script>

<style lang="scss" scoped>
body{
    overflow: hidden;
}
</style>